import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/albignasolar/1.png"
import img2 from "../../images/albignasolar/2.png"
import img3 from "../../images/albignasolar/3.png"
import img4 from "../../images/albignasolar/4.png"

import img5 from "../../images/albignasolar/5.png"
import img6 from "../../images/albignasolar/6.png"
import img7 from "../../images/albignasolar/7.png"

import img8 from "../../images/albignasolar/8.png"
import img9 from "../../images/albignasolar/9.png"


// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    Albigna lake is a reservoir 2163m above sea level. It is the first dam wall in Switzerland with added solar panels.
                    <p/>
                    I wrote the text for the website presenting the project, which allows citizens to participate by purchasing their energy from the new facility.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    Der Albigna-Stausee liegt 2163 über Meer. Seine Staumauer ist die erste in der Schweiz mit einer Solaranlage.
                    <p/>
                    Ich schrieb den Text für die Webseite um Bürger für das Projekt zu begeistern, denn sie können es mit dem Kauf von Solarstrom mitfinanzieren.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>





    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>
    <img src={img5}/>
    <img src={img6}/>
    <img src={img7}/>
    <img src={img8}/>
    <img src={img9}/>








      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
